import GynecologieMixin from "@/mixins/Gynecologie.js"

export default {
	mixins: [GynecologieMixin],
	methods: {
		async allSeason() {
			const season = await this.loadSeasons()
			return season.map(res => res.season_label)
		},
		conditionReglement() {
			return [
				'invoice.duedate_config.a_facturation',
				'invoice.duedate_config.30j',
				'invoice.duedate_config.30j_fin_mois',
				'invoice.duedate_config.10j_mois',
				'invoice.duedate_config.fin_mois_suivant'
			]
		},
		actionReadMicroship() {
			return [{
				value: 'add_or_open_fiche',
				label: 'licence_config.microship_action.add_or_open_fiche'
			},
			{
				value: 'add_or_open_acts',
				label: 'licence_config.microship_action.add_or_open_acts'
			}]
		},
		bool() {
			return [false]
		},
		date() {
			return [new Date()]
		},
		text(){
			return ['']
		}
	}
}
